import React from "react";
import { Link } from "react-router-dom";
import "../../index.css"

const WebDevelopement = () => {
    return (

        // Main Body of Section Starts
        <div className="App">

            {/* About us section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h4 className="text-white title-dark text-capitalize"> web development </h4>
                                <p className="text-white mb-0 mx-auto">
                                    We provide you with the expertise and technology you need to make <br /> an impact with your product.
                                </p>
                                <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About us section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png"  height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}

            {/* Web Development First section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/web_dev/web.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">We make it about your business goals, not just about web development </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We provide you with the expertise and technology you need to make an impact with your product. From top UX and product design to efficient development.
                                </p>
                                <div className="mt-4">
                                    <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Web Development First section End */}


            {/* Web Development Third section Starts */}
            <section className="bg-light d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">Web development done right </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We take web development services seriously. We will guide you through the whole process. Evaluate your project, research the competition, and consult on the technology stack, architecture, features, and post-launch enhancements.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    With the right approach to web application development, we will turn your product into a revenue magnet. Quick MVP development, dedicated PDM for the product, top-notch design team - all at your disposal. We write the future, not only code.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/web_dev/coding.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Web Development Third section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>

            {/* Web Development First section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/web_dev/web_dev_process.webp" width="" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Our way of the web development process </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We make the delivery approach more product-centric and put the product value at the forefront of the whole web development process. We work with companies at every stage of the product life cycle: from defining product strategy to scaling and sustaining market share.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Our support does not stop there. After delivering your product, we are here to make sure it is in a good condition and sustains on the market.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Web Development First section End */}

            {/* Product Delivery Lifecycle section Starts */}
            <section className="bg-light d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col--6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div className="section-title ms-lg-4">
                                <p className="text-primary h2 mb-3"><i className="uil uil-apps"></i></p>
                                <h4 className="title mb-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Doltech Product Delivery Lifecycle</h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    Do you have a project? Let’s talk about solutions!
                                </p>
                                <div className="mt-4">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col--6 col-12">
                            <div className="row me-lg-4" id="counter">
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-desktop fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Insight</h5>
                                                    <p className="text-muted mb-0">
                                                        We want to understand the problem, and the client, and define a solution
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-users-alt fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Discovery</h5>
                                                    <p className="text-muted mb-0">
                                                        We verify the initial product strategy for the market with our future customers
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="row pt-lg-4 mt-lg-4">
                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-rocket fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Introduction</h5>
                                                    <p className="text-muted mb-0">
                                                        We deliver your product and prepare metrics to validate the assumptions
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-award fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Maturity</h5>
                                                    <p className="text-muted mb-0">
                                                        We focus on customer satisfaction and sustaining market share
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Product Delivery Lifecycle section End */}

            <section className="d-table w-100">
                <br /><br />
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title mb-4 pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">Web app development services</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <ul className="nav nav-pills nav-justified flex-column rounded" id="pills-tab2" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link rounded active" id="pills-cloud-tab" data-bs-toggle="pill" href="#pills-cloud" role="tab" aria-controls="pills-cloud" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                            <h5>Frontend Development</h5>
                                            <p className="text-muted tab-para mb-0">
                                                For slick user experiences, and functional application options.
                                            </p>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link border-top rounded" id="pills-smart-tab" data-bs-toggle="pill" href="#pills-smart" role="tab" aria-controls="pills-smart" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                            <h5>Backend Development</h5>
                                            <p className="text-muted tab-para mb-0">
                                                For easy maintenance, quality performance, and enhancements.
                                            </p>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link border-top rounded" id="pills-apps-tab" data-bs-toggle="pill" href="#pills-apps" role="tab" aria-controls="pills-apps" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                            <h5>Product Consulting</h5>
                                            <p className="text-muted tab-para mb-0">
                                                For additional technical and market opportunities.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className=" position-relative">
                                <div className="tab-content ms-lg-4" id="pills-tabContent2">
                                    <div className="tab-pane fade show active wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-cloud" role="tabpanel" aria-labelledby="pills-cloud-tab">
                                        <img src="../../../../assets/images/web_dev/web_dev_process.webp" width="450" className="img-fluid mx-auto d-block rounded" alt="" />
                                    </div>

                                    <div className="tab-pane fade wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-smart" role="tabpanel" aria-labelledby="pills-smart-tab">
                                        <img src="../../../../assets/images/web_dev/backend.webp" width="350" className="img-fluid mx-auto d-block" alt="" />
                                    </div>

                                    <div className="tab-pane fade wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-apps" role="tabpanel" aria-labelledby="pills-apps-tab">
                                        <img src="../../../../assets/images/web_dev/product_consult.webp" width="350" className="img-fluid mx-auto d-block" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>
            {/* Product Delivery Lifecycle section End */}

            {/* Technology section Starts */}
            <section class="section bg-light">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Technologies</h4>
                                <p class="text-muted para-desc mb-0 mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    Adding the right pieces to your tech stack is a key part of making your project a success. We have everything you need to build and keep up your product and make sure it gets noticed.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-react fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">React JS</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-angularjs fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Angular JS</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-nodejs fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Node JS</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-python fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Django</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-java fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Java</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-php fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Php</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-javascript fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">JavaScript</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-aws fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">AWS</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Technology section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>

        </div>
    );
}

export default WebDevelopement;