import React from "react";
import "../../index.css";


const MachineLearning = () => {
    return (
        <div className="App">
            {/* Machine Learning section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h4 className="text-white title-dark text-capitalize">
                                    Harness the power of <br />  Machine Learning and Generative AI
                                </h4>
                                <p className="text-white mb-0 mx-auto">
                                    You can implement dedicated ML solutions, or integrate tools like ChatGPT to optimize <br /> your company's costs, improve internal efficiency, and gain a competitive advantage.
                                </p>
                                <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Let's Talk<i className="uil uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Machine Learning  section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png"  height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}

            
            {/* Machine Learning section Starts */}
            <section className=" d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    Machine Learning and Generative AI Solutions </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We've worked with AI companies of all sizes, as well as banks and FinTechs, specializing in everything from personal finances and wealth management, to business finance. Here's why we're the right choice for you when it comes to Machine Learning:
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/ai.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Machine Learning section End */}

            {/* Technology section Starts */}
            <section className="d-table w-100 bg-light">
                <br />  <br />
                <div className="container">

                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div className="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow" data-wow-delay=".1s">
                                <div className="card-body py-5">
                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                        <i className="uil uil-chart-bar fs-2"></i>
                                    </div>
                                    <div className="content mt-3 ">
                                        <h5><a href="javascript:void(0)" className="title text-dark text-capitalize">We understand the many benefits that Machine Learning can bring to business</a></h5>
                                        <p className="para-desc text-muted">
                                            We can implement ML solutions that will help your business to reduce costs, gain deeper customer insights, automate internal processes, improve fraud detection, and more.<br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div className="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow" data-wow-delay=".2s">
                                <div className="card-body py-5">
                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                        <i className="uil uil-tachometer-fast fs-2"></i>
                                    </div>
                                    <div className="content mt-3">
                                        <h5><a href="javascript:void(0)" className="title text-dark text-capitalize">We can help you optimize your product with the use of ML</a></h5>
                                        <p className="para-desc text-muted">
                                            From Robo-advisors and stock market forecasting to fighting fraud, we've got the right people on hand to advise you on what solutions you need to optimize your FinTech product. If you operate in another industry, we'll work together to find the right solution for your business, too.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div className="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow" data-wow-delay=".3s">
                                <div className="card-body py-5">
                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                        <i className="uil uil-users-alt fs-2"></i>
                                    </div>
                                    <div className="content mt-3">
                                        <h5><a href="javascript:void(0)" className="title text-dark text-capitalize">We have a team of experts skilled in Al applications</a></h5>
                                        <p className="para-desc text-muted">
                                            We have developers in our midst who are experts in Al, particularly in the following areas: large language models, natural language processing, computer vision, and more. The team is on top of the latest developments in the Al application and we put them to use in our projects.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                            <div className="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow" data-wow-delay=".1s">
                                <div className="card-body py-5">
                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                        <i className="uil uil-desktop fs-2"></i>
                                    </div>
                                    <div className="content mt-3 ">
                                        <h5><a href="javascript:void(0)" className="title text-dark text-capitalize">We want to make your app as 'intelligent' as possible</a></h5>
                                        <p className="para-desc text-muted">
                                            Machine Learning may not be suited to every app, but if we think that it can enhance your project, we'll let you know. We understand that quality data is the basis for enabling a machine to do its job well, so we'll do many tests on sample datasets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                            <div className="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow" data-wow-delay=".2s">
                                <div className="card-body py-5">
                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                        <i className="uil uil-award fs-2"></i>
                                    </div>
                                    <div className="content mt-3">
                                        <h5><a href="javascript:void(0)" className="title text-dark text-capitalize">
                                            We will boost your business with external ML API Integration</a></h5>
                                        <p className="para-desc text-muted">
                                            We specialize in integrating external Machine Learning APIs from platforms like AWS and OpenAl to reduce costs, automate processes, or gain valuable insights into customer behavior. <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            {/* Product Delivery Lifecycle section Starts */}
            <section className=" d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col--6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div className="section-title ms-lg-4">
                                <p className="text-primary h2 mb-3"><i className="uil uil-apps"></i></p>
                                <h4 className="title mb-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Our Machine Learning solutions</h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    Here are some of our Machine Learning tools & services.
                                </p>
                                <div className="mt-4">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col--6 col-12">
                            <div className="row me-lg-4" id="counter">
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-headphones-alt fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Support</h5>
                                                    <p className="text-muted mb-0">
                                                        Customer service for FinTech, face ID for retail and many more
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-desktop fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Application</h5>
                                                    <p className="text-muted mb-0">
                                                        Optical character recognition, face ID, chatbots and more.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="row pt-lg-4 mt-lg-4">
                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-rocket fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">SaaS</h5>
                                                    <p className="text-muted mb-0">
                                                        Natural language processing, computer vision, automatic speech recognition, and more
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className="uil uil-laptop-connection fs-2"></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Infrastructure</h5>
                                                    <p className="text-muted mb-0">
                                                        Security, threat or anomaly detection, predictive maintenance or data center automation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Product Delivery Lifecycle section End */}

            {/* Web Development First section Starts */}
            <section className="d-table w-100 bg-light">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/ml.webp" width="" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Leveraging Cutting Edge Generative AI for Technology Companies </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    At Doltech, we have a deep understanding of the transformative power of generative AI for technology companies. We leverage solutions from trusted providers such as OpenAI, StableDiffusion, and Midjourney to help our clients optimize their operations and enhance their products.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Whether you want to improve your digital customer experience, automate internal processes, or streamline decision-making, our team of experts can help you select and implement the right generative AI tool.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Web Development First section End */}

            {/* Machine Learning section Starts */}
            <section className=" d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                Artificial Intelligence and FinTech </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                You might be unsure about whether your financial services product would benefit from data science or other forms of artificial intelligence. Our advice would be to begin by looking at your USP.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                Looking to improve your digital customer experience? Consider the implementation of a tailored chatbot. Want to eliminate fraudulent transactions and guarantee that your bank or Fintech is the safest on the market? Consider building an ML model for fighting fraud and building this into your product.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/fin.webp" width="" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Machine Learning section End */}

                        {/* Web Development First section Starts */}
                        <section className="d-table w-100 bg-light">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/do.webp" width="" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Do you need Artificial Intelligence? </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                The concepts of Machine Learning and artificial intelligence are often misinterpreted. We tend to think of them in terms of the revolutionary outcomes that they can produce but it depends on the use cases and the nature of the problems you’re trying to solve.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                We need to understand that although Machine Learning solves various complex problems, it is indeed not the solution to every problem that we face. Sometimes, it is overkill to apply such techniques to problems that could be solved otherwise using simpler and more straightforward approach.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Web Development First section End */}

                        {/* Machine Learning section Starts */}
                        <section className=" d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                MLOps at Doltech </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                At Doltech, we pride ourselves on being one of the first software houses to embrace MLOps. It is all about making ML operational and creating implementable models that are vital to streamlining the maturation process of AI and ML projects within an organization.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                MLOps is essentially DevOps for Machine Learning. It’s the collaborative relationship between data scientists and the operations or production teams. It is designed not only to improve automation to streamline with repeatable ML processes, but also to provide deeper, more consistent, and more useful insights, enabling us to provide you with customized solutions.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/ai.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Machine Learning section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>
        </div>
    );
}

export default MachineLearning;