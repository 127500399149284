import React from "react";

const UiUxDesign = () => {
    return (
        <div className="App">
            {/* UI & UX Design section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h4 className="text-white title-dark text-capitalize">
                                    A well-designed product <br /> means increased sales and market share
                                </h4>
                                <p className="text-white mb-0 mx-auto">
                                    Want to stand out from the crowd? Our award-winning design team is <br /> among the top 30 in the world. We'll make it happen!
                                </p>
                                <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Let's Talk<i className="uil uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* UI & UX Design  section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png"  height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}

            {/* UI & UX Third section Starts */}
            <section className="bg-light d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    Why choose Design Services at Doltech?</h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We believe that a product designer's role is not just to deliver creative solutions and stunning user interfaces, but to leave you with a product which has a high chance of market success.
                                </p>

                                <div className="mt-4">
                                    <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Work With Us <i className="uil uil-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/ui_ux/design.webp" width="400" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* UI & UX Third section End */}

            {/* UI & UX section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/ui_ux/ui.webp" width="300" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">UI & UX Design </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    With a UX Design team, you’ll be able to tackle all the needs of your users and business by applying various processes that also take the quality of the interaction into consideration. The ultimate goal is to create a great user experience, thus driving your sales.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Work With Us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* UI & UX section End */}

            {/* Technology section Starts */}
            <section class="d-table w-100 bg-light">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">Standalone services</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-desktop fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Design System</a></h5>
                                        <p className="para-desc text-muted">
                                            UX design and research are like a match made in tech heaven. They're the dynamic duo that saves users from frustrating interfaces and businesses from plummeting sales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-vector-square-alt fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Prototype</a></h5>
                                        <p className="para-desc text-muted">
                                            We create a fully interactive experience that feels like a real app, taking care of user flows, dead ends, and error messages, so you can focus on bringing your idea to life!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-object-ungroup fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">MVP</a></h5>
                                        <p className="para-desc text-muted">
                                            We turn your idea into a product, focusing on solving problems and finding market fit. Our design process covers discovery, wireframing, and prototyping.<br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            {/* UI & UX Third section Starts */}
            <section className="d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    We make the effort to <span className="getToKnowYouColor">get to know you</span></h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    When conducting a UX Audit, we’ll never just take your product at face value. We’ll take a very thorough brief, including information about your market and the sector within which you work, as well as your stage of development and your plans for the future. All of this means that we’ll be able to offer you very tailored advice.
                                </p>

                                {/* <div className="mt-4">
                                    <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/ui_ux/get.webp" width="310" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* UI & UX Third section End */}

            {/* Technology section Starts */}
            <section class="d-table w-100 bg-light">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">The Design Process</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-image-search fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">The Discovery Phase</a></h5>
                                        <p className="para-desc text-muted">
                                            This is where we conduct a workshop with you with the aim of doing a deep-dive into your business, the sector within which you operate, and your product idea. This allows us to get a firm understanding of your business needs before we get down to the nitty-gritty of designing your product.
                                            Simply put, we want to ensure that we totally understand what you want to achieve with us, and then we’ll gather our tools in order to make it happen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-laptop fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Agile Design Creation</a></h5>
                                        <p className="para-desc text-muted">
                                            This is the core design phase in which we work on your project. We’ll be in touch at various stages of the journey to ensure that you are happy with the progress being made.
                                            We may also offer suggested improvements and we’ll ensure we’re always there to answer any questions you might have along the way.
                                            <br /><br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-airplay fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Validation</a></h5>
                                        <p className="para-desc text-muted">
                                            We always perform our own internal tests, but we also encourage you to do your own to ensure that you’re entirely happy with the finished product. This method of working saves money, because ultimately you don’t invest anything in development until you’re absolutely sure that it works. We pride ourselves on delivering our work on time and on budget.<br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            {/* UI & UX section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/mobile_dev/cost.webp" width="300" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Consultancy </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                When focusing on your digital products it’s valuable to make use of a design consultant, who will be your sidekick in ensuring everything from the design perspective is covered from leading workshops, guiding the creative direction facilitating the research and more.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Work With Us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* UI & UX section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>

        </div>
    );
}

export default UiUxDesign;