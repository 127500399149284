import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
    return (
        <div className="App">

            {/* About us section starts */}
            <section className="bg-half-170 d-table w-100" id="aboutUsImage">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h2 className="text-white title-dark"> Start your project with Doltech! </h2>
                                <p className="text-white mb-0 mx-auto">
                                    Fill out the form, and tell us more about your business goals.<br /> We’ll get back to you within 1 working day.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About us section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}
            {/* Curvy Section Ends */}

            <section class="section">
                <br /><br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="mb-4">Find the help you need</h4>
                                <p class="para-desc mx-auto text-muted">
                                    Would you rather directly get in touch? We always have the <br /> time for a call or email!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center">
                                <div class="card-body">
                                    <div class="icons rounded-circle shadow-lg d-inline-block mb-2 h3">
                                        <i class="uil uil-envelope fs-2"></i>
                                    </div>
                                    <div class="content mt-4">
                                        <a href="helpcenter-faqs.html" class="title h5 text-dark">Email</a>
                                        <p class="text-muted mt-3 mb-0">
                                            info@doltech.io
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center">
                                <div class="card-body">
                                    <div class="icons rounded-circle shadow-lg d-inline-block mb-2 h3">
                                        <i class="uil uil-phone fs-2"></i>
                                    </div>
                                    <div class="content mt-4">
                                        <a href="helpcenter-guides.html" class="title h5 text-dark">Call / Support</a>
                                        <p class="text-muted mt-3 mb-0">
                                            +233 248 920 770
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center">
                                <div class="card-body">
                                    <div class="icons rounded-circle shadow-lg d-inline-block mb-2 h3">
                                        <i class="uil uil-map-pin fs-2"></i>
                                    </div>
                                    <div class="content mt-4">
                                        <a href="helpcenter-support-request.html" class="title h5 text-dark">Location</a>
                                        <p class="text-muted mt-3 mb-0">
                                            Spintex -  Accra
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>

            {/* Contact Us Section Starts */}
            {/* <section class="vh-100 d-flex align-items-center contactUsImage">
                <div class="bg-overlay bg-overlay-white"></div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                            <div class="title-heading mt-5 pt-4">
                                <h2 class="">Let's talk about your business</h2>
                                <p class="text-dark">
                                    Start working with <span class="text-primary fw-bold">Doltech</span> and
                                    the next market success could be yours. Let’s make it happen,
                                    get in touch with us

                                </p>

                                <div class="d-flex contact-detail align-items-center mt-3">
                                    <div class="icon">
                                        <i class="uil uil-envelope fea icon-m-md text-dark me-3"></i>
                                    </div>
                                    <div class="flex-1 content">
                                        <h6 class="title fw-bold mb-0">Email</h6>
                                        <Link to="mailto:admin@doltech.io" class="text-primary" id="textHoverContact">admin@doltech.io</Link>
                                    </div>
                                </div>

                                <div class="d-flex contact-detail align-items-center mt-3">
                                    <div class="icon">
                                        <i class="uil uil-phone fea icon-m-md text-dark me-3"></i>
                                    </div>
                                    <div class="flex-1 content">
                                        <h6 class="title fw-bold mb-0">Phone</h6>
                                        <Link to="tel:+233248920770" class="text-primary" id="textHoverContact">+233 248 920 770</Link>
                                    </div>
                                </div>

                                <div class="d-flex contact-detail align-items-center mt-3">
                                    <div class="icon">
                                        <i class="uil uil-map-pin fea icon-m-md text-dark me-3"></i>
                                    </div>
                                    <div class="flex-1 content">
                                        <h6 class="title fw-bold mb-0">Location</h6>
                                        <Link to="https://goo.gl/maps/RVFsEyNtLRCtMXb56" data-type="iframe" class="video-play-icon text-primary lightbox" id="textHoverContact">View on Google map</Link>
                                    </div>
                                </div>
                                <ul class="list-unstyled social-icon mb-0 mt-4">
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-facebook fea icon-sm fea-social"></i></Link></li>
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-instagram fea icon-sm fea-social"></i></Link></li>
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-twitter fea icon-sm fea-social"></i></Link></li>
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-linkedin fea icon-sm fea-social"></i></Link></li>
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-github fea icon-sm fea-social"></i></Link></li>
                                    <li class="list-inline-item"><Link to="#!" class="rounded"><i class="uil uil-gitlab fea icon-sm fea-social"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* Contact Us Section Starts */}
        </div>
    );
}

export default Contact;