import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="app">
            <footer className="footer footer-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-py-60">
                                <div className="row">
                                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                        <a href="/" className="logo-footer">
                                            <img src="../../../../assets/images/logo/logo.png" height="50" alt="" />
                                        </a>
                                        <p className=""><span className="textColor fw-bold">Doltech</span> is an agile software house that produces applications of the highest quality. We have completed a number of projects for companies of all sizes, from individual
                                            startups to large corporations.
                                        </p>
                                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                                            <li className="list-inline-item"><a href="#!" className="rounded"><i className="uil uil-facebook-f fea icon-sm fea-social"></i></a></li>
                                            <li className="list-inline-item"><a href="#!" className="rounded"><i className="uil uil-instagram fea icon-sm fea-social"></i></a></li>
                                            <li className="list-inline-item"><a href="#!" className="rounded"><i className="uil uil-twitter-alt fea icon-sm fea-social"></i></a></li>
                                            <li className="list-inline-item"><a href="#!" className="rounded"><i className="uil uil-linkedin-alt fea icon-sm fea-social"></i></a></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Company</h5>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><a href="/" className="text-foot">Home</a></li>
                                            <li><a href="about-us" className="text-foot">About us</a></li>
                                            <li><a href="porfolio" className="text-foot">Portfolio</a></li>
                                            <li><a href="contact-us" className="text-foot">Contact us</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Services</h5>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><a href="web-developement" className="text-foot">Web Development</a></li>
                                            <li><a href="mobile-app-development" className="text-foot">Mobile Development</a></li>
                                            <li><a href="chatgpt-integration" className="text-foot">ChatGPT Integration</a></li>
                                            <li><a href="machine-learning" className="text-foot">Machine Learning</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Contact Info</h5>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><a href="tel:+233248920770" className="text-foot"><span className="uil uil-phone"></span><span className="contactinfo">+233 248 920 770</span></a></li>
                                            <li><a href="mailto:admin@doltech.io" className="text-foot"><span className="uil uil-envelope-edit"></span><span className="contactinfo">info@doltech.io</span></a></li>
                                            <li><a href="#!" className="text-foot"><span className="uil uil-map-marker"></span><span className="contactinfo">Spintex, Accra - Ghana</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-py-30 bg-footer text-white">
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <div className="text-sm-center">
                                    <p className="mb-0">© 2023 Doltech Ltd. All Right Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer;