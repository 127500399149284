import React from "react";

import "../index.css"

const Portfolio = () => {
    return (
        <div className="App">
            {/* ChatGPT Integration section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading  title-heading">
                                <h4 className="text-white title-dark text-capitalize">
                                    Doltech Portfolio
                                </h4>
                                {/* <p className="text-white mb-0 mx-auto">
                                    Stand out from competitors by integrating ChatGPT to enhance business operations  and <br /> provide exceptional customer experiences.
                                </p> */}
                                {/* <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Get Started<i className="uil uil-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ChatGPT Integration   section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}


            <section className="d-table w-100">
                <br /><br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title mb-4 pb-2 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <h4 className="title">Our Works</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 pt-2 text-left">
                            <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded bg-light wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link rounded active" id="community-tab" data-bs-toggle="pill" href="#pills-community" role="tab" aria-controls="pills-community" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">All</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="selfservice-tab" data-bs-toggle="pill" href="#pills-self" role="tab" aria-controls="pills-self" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Wesites</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="teamwork-tab" data-bs-toggle="pill" href="#pills-teamwork" role="tab" aria-controls="pills-teamwork" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Web Apps</h6>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link rounded" id="maintenance-tab" data-bs-toggle="pill" href="#pills-maintenance" role="tab" aria-controls="pills-maintenance" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Mobile Apps</h6>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4 pt-2">
                            <div className="tab-content" id="pills-tabContent">

                                <div className="tab-pane fade show active" id="pills-community" role="tabpanel" aria-labelledby="community-tab">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                            <div className="card work-container work-primary work-modern rounded border-0 overflow-hidden wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body p-0">
                                                    <img src="../../../../assets/images/portfolio/adpromac.png" className="img-fluid rounded" alt="work-image" />
                                                    <div className="content">
                                                        <a href="https://adpromac.com/" target="_blank" className="title text-white pb-2 border-bottom">ADPROMAC</a>
                                                        <ul className="post-meta mb-0 mt-2 text-white list-unstyled">
                                                            <li className="list-inline-item me-3 text-white "><i className="uil uil-globe me-2"></i><a href="https://adpromac.com/" target="_blank" className="text-white">www.adpromac.com</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-absolute top-0 end-0 m-3 z-index-1">
                                                        <a href="https://adpromac.com/" target="_blank" className="btn btn-primary btn-pills btn-sm btn-icon "><i className="uil uil-angle-right-b"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                            <div className="card work-container work-primary work-modern rounded border-0 overflow-hidden wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body p-0">
                                                    <img src="../../../../assets/images/portfolio/cyberghana.png" className="img-fluid rounded" alt="work-image" />
                                                    <div className="content">
                                                        <a href="https://www.cyberghana.org/" target="_blank" className="title text-white pb-2 border-bottom">CyberGhana</a>
                                                        <ul className="post-meta mb-0 mt-2 text-white list-unstyled">
                                                            <li className="list-inline-item me-3 text-white "><i className="uil uil-globe me-2"></i><a href="https://www.cyberghana.org/" target="_blank" className="text-white">www.cyberghana.org</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-absolute top-0 end-0 m-3 z-index-1">
                                                        <a href="https://www.cyberghana.org/" target="_blank" className="btn btn-primary btn-pills btn-sm btn-icon "><i className="uil uil-angle-right-b"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-self" role="tabpanel" aria-labelledby="selfservice-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title me-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Early Market Validation</h5>
                                                <p className="text-muted">
                                                    We now have a thorough grasp of the issue and a theory for a potential fix. Assessing
                                                    the accuracy of our assumptions is the next step. This phase's objective is to rapidly
                                                    and inexpensively determine from our prospective users whether it is worthwhile to
                                                    develop our solution.
                                                </p>
                                                <div className="accordion mt-4" id="buyingquestion">
                                                    <div className="accordion-item rounded">
                                                        <h5 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                Mockups and experiments
                                                            </button>
                                                        </h5>
                                                        <div id="collapseOne" className="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                                            data-bs-parent="#buyingquestion">
                                                            <div className="accordion-body text-muted">
                                                                We will conduct a series of simple experiments to verify the most important
                                                                theories on the appeal, viability, and profitability of your product.
                                                                Different early-stage testing techniques, such wireframe mockups, can aid in
                                                                validating products already on the market and minimizing waste. This is a
                                                                fantastic opportunity to obtain the first insightful user input on the concept.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="accordion-item rounded mt-2">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                aria-expanded="false" aria-controls="collapseTwo">
                                                                Design Sprint
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                                            data-bs-parent="#buyingquestion">
                                                            <div className="accordion-body text-muted">
                                                                We can quickly transform an idea into a working prototype through the use of Design
                                                                Sprint workshops. It only takes four days to complete the sprint, and you only need
                                                                to participate in the first two days.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="accordion-item rounded mt-2">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                UX research and testing
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                                                            data-bs-parent="#buyingquestion">
                                                            <div className="accordion-body text-muted">
                                                                Full-fledged product development is costly and fraught with danger, as it runs the
                                                                risk of failing to satisfy clients. We will collaborate with you to develop a more
                                                                complete prototype and test it with actual users through our networks or database
                                                                of focus groups using the preliminary information acquired at the mockup stage.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="accordion-item rounded mt-2">
                                                        <h2 className="accordion-header" id="headingFour">
                                                            <button className="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                Landing pages
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" className="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                                                            data-bs-parent="#buyingquestion">
                                                            <div className="accordion-body text-muted">
                                                                As part of user testing, we can also create straightforward landing pages that can
                                                                serve as your product's minimum viable product (MVP), complete with a powerful
                                                                analytics engine that will allow you to track traffic and button clicks and obtain
                                                                insightful information about user behavior.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 order-1 order-md-2">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/validation.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-teamwork" role="tabpanel" aria-labelledby="teamwork-tab">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                            <div className="card work-container work-primary work-modern rounded border-0 overflow-hidden wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body p-0">
                                                    <img src="../../../../assets/images/portfolio/adpromac.png" className="img-fluid rounded" alt="work-image" />
                                                    <div className="content">
                                                        <a href="https://adpromac.com/" target="_blank" className="title text-white pb-2 border-bottom">ADPROMAC</a>
                                                        <ul className="post-meta mb-0 mt-2 text-white list-unstyled">
                                                            <li className="list-inline-item me-3 text-white "><i className="uil uil-globe me-2"></i><a href="https://adpromac.com/" target="_blank" className="text-white">www.adpromac.com</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-absolute top-0 end-0 m-3 z-index-1">
                                                        <a href="https://adpromac.com/" target="_blank" className="btn btn-primary btn-pills btn-sm btn-icon "><i className="uil uil-angle-right-b"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                            <div className="card work-container work-primary work-modern rounded border-0 overflow-hidden wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body p-0">
                                                    <img src="../../../../assets/images/portfolio/cyberghana.png" className="img-fluid rounded" alt="work-image" />
                                                    <div className="content">
                                                        <a href="https://www.cyberghana.org/" target="_blank" className="title text-white pb-2 border-bottom">CyberGhana</a>
                                                        <ul className="post-meta mb-0 mt-2 text-white list-unstyled">
                                                            <li className="list-inline-item me-3 text-white "><i className="uil uil-globe me-2"></i><a href="https://www.cyberghana.org/" target="_blank" className="text-white">www.cyberghana.org</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-absolute top-0 end-0 m-3 z-index-1">
                                                        <a href="https://www.cyberghana.org/" target="_blank" className="btn btn-primary btn-pills btn-sm btn-icon "><i className="uil uil-angle-right-b"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-maintenance" role="tabpanel" aria-labelledby="maintenance-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ms-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Product Maintenance</h5>
                                                <p className="text-muted">
                                                    Once your product is operational, it will probably need some continuing upkeep. This phase's objectives
                                                    include fixing any remaining defects, addressing potential security concerns, and making any necessary
                                                    minor improvements. A thorough infrastructure health check might also be included. Why is the phase of
                                                    product maintenance crucial?
                                                </p>
                                                <div className="mt-4">
                                                    <ul className="list-unstyled text-muted">
                                                        <li className="mb-1"><span className="text-primary iconColor h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>It will keep your product in a good condition to satisfy changing market needs.</li>
                                                        <li className="mb-1"><span className="text-primary iconColor h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>It covers all technological upgrades, resulting in a continued high-quality user experience.</li>
                                                        <li className="mb-1"><span className="text-primary iconColor h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Any small bugs can be quickly and efficiently managed.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/maintenance.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>
        </div>
    );
}

export default Portfolio;