import React from "react";
import "../../index.css";

const MobleAppDevelopment = () => {
    return (
        <div className="App">
            {/* Mobile App Development section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h4 className="text-white title-dark text-capitalize"> Deliver a seamless,<br /> impactful experience across all devices</h4>
                                <p className="text-white mb-0 mx-auto">
                                    We will help you design intuitive, native, and cross-platform <br /> mobile apps for iOS and Android.
                                </p>
                                <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Let's Talk<i className="uil uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Mobile App Development section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png"  height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}

            {/* Mobile App Development Third section Starts */}
            <section className="bg-light d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    Why choose Doltech to create your mobile app? </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We're product delivery partners, which means that we're with you at every step of your mobile app development journey. We take your app idea, help you validate it, and work with you to create a top user experience.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We create and maintain a highly available infrastructure in the public cloud. Thanks to automation and CI/CD processes, we enable our mobile developers to deliver your app faster and to avoid risk. Our ultimate goal is to provide you with a successful app that really impresses your users.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/mobile_dev/mobile.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Mobile App Development Third section End */}

            {/* Technology section Starts */}
            <section class="section">
                <br />  <br />
                <div class="container">

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-apps fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">we have an experience team of skilled mobile app developers</a></h5>
                                        <p className="para-desc text-muted">
                                            All members of the team are senior developers with a weath of exeperienced. Machine learning, augmented realiity, blockchain wallets - you name it, we've done it. We also pride ourselves on being at the cutting edge of the latest technology in mobile app development.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-mobile-android fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">we create tailor-made mobile app for both iOS and Andriod</a></h5>
                                        <p className="para-desc text-muted">
                                            We have experts in front-end and back-end development, designs, branding and app maintenance, with excellent inter-team communication, meaning that you don't have to look anywhere else.<br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp shadow-md" data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-object-group fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">we'll make sure your mobile app has outstanding UX</a></h5>
                                        <p className="para-desc text-muted">
                                            we pride ourselves on delivering an excellent ecperience to your end user, as we know that this is what makes or breaks a mobile app. Our skilled UX designers weill do the research into your target. market, conduct usability tests and make sure that your messaging is spot on.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Technology section End */}

            {/* Technology section Starts */}
            <section class="section bg-light">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class="title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">The Mobile App Development Process</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <ul className="nav nav-pills nav-justified flex-column rounded" id="pills-tab2" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link rounded active" id="pills-cloud-tab" data-bs-toggle="pill" href="#pills-cloud" role="tab" aria-controls="pills-cloud" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                            <h6>Exploration</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link border-top rounded" id="pills-smart-tab" data-bs-toggle="pill" href="#pills-smart" role="tab" aria-controls="pills-smart" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                            <h6 className="text-capitalize">Building an end-to-end solution</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link border-top rounded" id="pills-apps-tab" data-bs-toggle="pill" href="#pills-apps" role="tab" aria-controls="pills-apps" aria-selected="false">
                                        <div className="p-3 text-start wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                            <h6 className="text-capitalize">maintenance and support</h6>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className=" position-relative">
                                <div className="tab-content ms-lg-4" id="pills-tabContent2">
                                    <div className="tab-pane fade show active wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-cloud" role="tabpanel" aria-labelledby="pills-cloud-tab">
                                        <img src="../../../../assets/images/mobile_dev/desk.png" width="100" className="img-fluid mx-auto d-block rounded" alt="" />
                                        <br />
                                        <p className="text-muted tab-para mb-0 pl-2 mx-auto d-block">
                                            This is the first stage of the mobile app development process in which we work with you to clarify the brief for your app and define your needs so that we can make sure we’re all on the same page and can deliver the right solution.
                                        </p>
                                    </div>

                                    <div className="tab-pane fade wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-smart" role="tabpanel" aria-labelledby="pills-smart-tab">
                                        <img src="../../../../assets/images/mobile_dev/solution.png" width="100" className="img-fluid mx-auto d-block" alt="" />
                                        <br />
                                        <p className="text-muted tab-para mb-0 pl-2 mx-auto d-block">
                                            Having completed the exploration stage, we start working on the UX and UI of your mobile app. We create wireframes which we share with you to demonstrate the flow from one page to the next. Once you approve these, we can start on implementing the business logic which will meet your exact requirements, and later the design and visual interface, which might include animations.
                                        </p>
                                        <br />
                                        <p className="text-muted tab-para mb-0 pl-2 mx-auto d-block">
                                            Our skilled iOS and Android developers are on hand, not only to ensure everything in your app is working as it should, but also to offer proactive suggestions for improvements. Because we work in Agile, after every 2-week Sprint, you will see the finished effects of your app.
                                        </p>
                                    </div>

                                    <div className="tab-pane fade wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-apps" role="tabpanel" aria-labelledby="pills-apps-tab">
                                        <img src="../../../../assets/images/mobile_dev/support.png" width="100" className="img-fluid mx-auto d-block" alt="" /><br />
                                        <p className="text-muted tab-para mb-0 pl-2 mx-auto d-block">
                                            We will fully explain how you can make changes in the app, and offer full maintenance and support to ensure that your app grows alongside your business. <br /><br /> We also monitor closely for bugs and check regularly check that your app is working as it should across different mobile devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Technology section End */}

            {/* Mobile App Development First section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/mobile_dev/native.webp" width="" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Native Apps </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    When we don't want to make compromises, be it on performance, security or maintenance, we can build apps using native frameworks available on given platforms.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Sometimes we may be constrained to a feature-set that mostly relies on native solutions, and using a cross platform framework doesn't make economical sense. Native apps will always be the most performant, safest, and will require much less effort to maintain in the long term.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Mobile App Development First section End */}

            {/* Mobile App Development Third section Starts */}
            <section className="bg-light d-table w-100">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    Cross-platform Apps </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Cross platform frameworks work hard to deliver on their main promise, which is to use a single codebase in as many places as possible.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    With the new wave of mobile frameworks, we can finally make this happen, and it has proven very effective. Using a single codebase for both platforms, significantly reduces development time and improves app maintenance - releases can be done simultaneously on iOS and Android.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/mobile_dev/cross.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Mobile App Development Third section End */}

            {/* Mobile App Development First section Starts */}
            <section className="d-table w-100 ">
                <br />  <br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/mobile_dev/cost.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp text-capitalize" data-wow-delay=".1s">Mobile App Development Costs </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Have a new idea for an app? Wondering how much it will cost to build? Mobile app development cost will largely be down to complexity, which is closely linked to the amount of features that you want to include and the functionality that you need.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    There are differences in cost depending on whether you're looking to build you app for an iOS or an Android operating system, or both. And if the answer is both, it might be worth looking at cross-platform mobile solutions.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Mobile App Development First section End */}

            {/* Technology section Starts */}
            <section class="section bg-light">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title text-center mb-4 pb-2">
                                <h4 class=" mb-0 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Mobile Technologies</h4>
                                <h4 class="title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">What do we Use to Build Apps?</h4>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-react fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">React Native</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <img src="../../../../assets/images/mobile_dev/flutter.png" width="30" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp flutterIcon" data-wow-delay=".3s"
                                            alt="Wed  Dev" />
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Flutter</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-kotlin fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Kotlin</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="mdi mdi-language-swift fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark">Swift</a></h5>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />  <br />
            </section>
            {/* Technology section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>
        </div>
    );
}

export default MobleAppDevelopment;