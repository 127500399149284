import React from "react";

const ChatGPTIntegration = () => {
    return (
        <div className="App">
            {/* ChatGPT Integration section starts */}
            <section className="bg-half-170 d-table w-100 web" id="">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h4 className="text-white title-dark text-capitalize">
                                    Transform your business with ChatGPT Integration
                                </h4>
                                <p className="text-white mb-0 mx-auto">
                                    Stand out from competitors by integrating ChatGPT to enhance business operations  and <br /> provide exceptional customer experiences.
                                </p>
                                <a href="contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Get Started<i className="uil uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ChatGPT Integration   section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Technology section Starts */}
            <section class="d-table w-100">
                <br />  <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">Which industries can gain from integration <br /> with ChatGPT? All of them!</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-image-search fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">
                                            E-commerce</a></h5>
                                        <p className="para-desc text-muted">
                                            Improve the online shopping experience.
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-laptop fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Education</a></h5>
                                        <p className="para-desc text-muted">
                                            Speed up learning and provide teachers with support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-airplay fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Customer Services</a></h5>
                                        <p className="para-desc text-muted">
                                            Deliver efficient and effect support to customers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-image-search fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">
                                            Healthcare</a></h5>
                                        <p className="para-desc text-muted">
                                            Create services for patients and medical professionals
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-laptop fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Your Industry</a></h5>
                                        <p className="para-desc text-muted">
                                            Put ChatGPT to work for your company and watch it grow.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-airplay fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Travel</a></h5>
                                        <p className="para-desc text-muted">
                                            Assist travellers at every stage of the trip
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-image-search fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">
                                            Finance</a></h5>
                                        <p className="para-desc text-muted">
                                            Offer a variety of services to users of FinTech platforms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-laptop fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Gaming</a></h5>
                                        <p className="para-desc text-muted">
                                            Improve game analytics, customer support or introduce smart chatbot
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-airplay fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">Marketing and Advertising</a></h5>
                                        <p className="para-desc text-muted">
                                            Improve the  effectiveness of marketing campaigns.
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Let’s talk about creating a </h4>
                                <h5 className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    product in your industry.
                                </h5>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Get In Touch</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>

            {/* Product Delivery Lifecycle section Starts */}
            <section className=" d-table w-100">
                <br />  <br />  <br />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col--6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div className="section-title ms-lg-4">
                                <p className="text-primary h2 mb-3"><i className="uil uil-apps"></i></p>
                                <h4 className="title mb-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    Business use cases of ChatGPT integration
                                </h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    Here are some of the use cases of chatGPT integration.
                                </p>
                                <div className="mt-4">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col--6 col-12">
                            <div className="row me-lg-4" id="counter">
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className=" fs-2">1</i>
                                                    </div>
                                                    <h5 className="mt-3 title">CRM Systems</h5>
                                                    <p className="text-muted mb-0 text-left">
                                                        Automate your customer support process, streamline, and improve customer satisfaction
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className=" fs-2">2</i>
                                                    </div>
                                                    <h5 className="mt-3 title">Marketing Automation</h5>
                                                    <p className="text-muted mb-0">
                                                        Create target marketing campaigns and deliver personalized message for the customers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="row pt-lg-4 mt-lg-4">
                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className=" fs-2">3</i>
                                                    </div>
                                                    <h5 className="mt-3 title">Content Management Systems</h5>
                                                    <p className="text-muted mb-0">
                                                        Improve engagement, increase time spent on your website, and supercharge conversions
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mt-4 pt-2">
                                            <div className="card features feature-primary explore-feature border-0 rounded text-center shadow-md wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                                <div className="card-body">
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i className=" fs-2">4</i>
                                                    </div>
                                                    <h5 className="mt-3 title">Chatbots</h5>
                                                    <p className="text-muted mb-0">
                                                        Use virtual assistants to provide personalized and interactive user experience
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br /> <br />
            </section>
            {/* Product Delivery Lifecycle section End */}

            {/* Technology section Starts */}
            <section class="d-table w-100 bg-light">
                <br />  <br /> <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">Why now is a perfect moment to  <br /> integrate with ChatGPT?</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-award fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">
                                            35%</a></h5>
                                        <p className="para-desc text-muted">
                                            AI Chat bots increase customer satisfaction
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-puzzle-piece fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">40%</a></h5>
                                        <p className="para-desc text-muted">
                                            AI technologies have the potential to propel productivity
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-dashboard fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize"> 38%</a></h5>
                                        <p className="para-desc text-muted">
                                            AI-powered businesses improve profitability
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            {/* Machine Learning section Starts */}
            <section className=" d-table w-100">
                <br />  <br /><br />
                <div className="container">
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="title-heading me-lg-4">
                                <h4 className=" mb-3 wow animate__animated animate__fadeInUp  text-capitalize" data-wow-delay=".1s">
                                    Would you like to integrate your business with AI? We’ll take your idea to fruition.</h4>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    To fully leverage the OpenAI API for your specific needs, you'll want a team of professional engineers on your side. We work with the latest versions of groundbreaking AI systems, including GPT-4.
                                </p>
                                <p className="para-desc text-muted wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Whether you're looking for an intelligent system that can analyze large quantities of data to unearth new insights or a smart chatbot that can engage with your consumers in a natural way, at Doltech we've got you covered.
                                </p>
                                {/* <div className="mt-4">
                                    <a href="page-contact-one.html" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Hire us <i className="uil uil-arrow-right"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/machine_learning/ai.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp rounded" data-wow-delay=".3s"
                                alt="Wed  Dev" />
                        </div>
                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Machine Learning section End */}

            {/* Technology section Starts */}
            <section class="d-table w-100 bg-light">
                <br />  <br /> <br />
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="section-title pb-2 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 class="title mb-4 text-capitalize">What do you gain by hiring Doltech?</h4>
                                {/* <hr /> */}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".1s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-award fs-2"></i>
                                    </div>
                                    <div class="content mt-3 ">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">
                                            Top-Level Expertise</a></h5>
                                        <p className="para-desc text-muted">
                                            We have top-notch experts who focus on AI Development Services
                                        </p>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".2s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-headphones-alt fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize">High-Quality customer service</a></h5>
                                        <p className="para-desc text-muted">
                                            Our team is there for our customers, ready to answer within 1 business day.
                                        </p>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2">
                            <div class="card features feature-primary explore-feature border-0 rounded text-center wow animate__animated animate__fadeInUp " data-wow-delay=".3s">
                                <div class="card-body py-5">
                                    <div class="icons rounded-circle shadow-lg d-inline-block">
                                        <i class="uil uil-thumbs-up fs-2"></i>
                                    </div>
                                    <div class="content mt-3">
                                        <h5><a href="javascript:void(0)" class="title text-dark text-capitalize"> Hight-end Technology</a></h5>
                                        <p className="para-desc text-muted">
                                            We use cutting-edge technology like OpenAI and Pinecon back to back with proven open-source frameworks like React.js or Django
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />  <br /><br />
            </section>
            {/* Technology section End */}

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Call us now and get</h4>
                                <h5 className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                a free consultation
                                </h5>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Get In Touch</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>
        </div>
    );
}

export default ChatGPTIntegration;