import React from "react";
import { Link, Outlet } from "react-router-dom";
import "../index.css"

import WebHeader from "../component/WebHeader";
import Footer from "../component/Footer";

const WebLayout = () => {
    return ( 
        <>
        <WebHeader />
        <Outlet />
        <Footer />
        </>
     );
}
 
export default WebLayout;