import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

// Doltech Website Layout
import WebLayout from './web pages/WebLayout';



// Doltech Website Pages
import Home from './web pages/Home';
import About from './web pages/About';
import Contact from './web pages/Contact';
import WebDevelopement from './web pages/services/WebDevelopment';
import MobleAppDevelopment from './web pages/services/MobileAppDevelopment';
import UiUxDesign from './web pages/services/UiUxDesign';
import MachineLearning from './web pages/services/MachineLearning';
import ChatGPTIntegration from './web pages/services/ChatGPTIntegration';
import Portfolio from './web pages/Portfolio';



//Welcom Page
// import DoltechWebLayout from './DoltechWebLayout';


// Doltech Webpage
// import Welcome from './Welcome';

export default function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<WebLayout />}>
          <Route index element={<Home />}/>
          <Route path='about-us' element={<About />}/>
          <Route path='contact-us' element={<Contact />}/>
          <Route  path='web-developement' element={< WebDevelopement />} />
          <Route path='mobile-app-development' element={< MobleAppDevelopment/>} />
          <Route path='ui-ux-design' element={< UiUxDesign />} />
          <Route path='machine-learning' element={< MachineLearning />} />
          <Route path='chatgpt-integration' element={< ChatGPTIntegration/>}/>
          <Route path='porfolio' element={< Portfolio/>}/>
      </Route>
    </Routes>
    </BrowserRouter>
  )
}
ReactDOM.render(<App />, document.getElementById("root"));