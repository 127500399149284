import React from "react";
import { Link } from "react-router-dom";
import "../index.css";

const Home = () => {
    return (
        <div className="App">
            {/* Loader */}
            <div id="preloader">
                <div id="status">
                    <div class="spinner">
                        <img src="../../../../assets/images/favicon/logo1.png" width="34" alt="" />
                    </div>
                </div>
            </div>
            {/* Loader End */}
            {/* Section One Start */}
            <section className="bg-half-260 bg-primary d-table w-100 LandbgImage it-home">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title-heading text-center mt-4">
                                <h1 className="display-2 title-dark text-white mb-3  wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Are you struggling to find the perfect IT <br /> solution to grow your business?</h1>
                                <p className="para-desc mx-auto text-white  wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Let's bring your idea to the market!</p>
                                <div className="mt-4 pt-2 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    <a to="contact-us" className="btn btn-primary getinTouch"> Get in touch <i className="ti uil-arrow-right icon"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section One Ends */}

            {/* Shape Start */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Shape Ends */}

            {/* Partners start */}
            <section className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png"  height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Partners End  */}

            <section className="section">
                <br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Why Choose Doltech</h4>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We are undoubtedly Ghana's finest software provider. We offer
                                    businesses innovative technological products and <br /> software development
                                    services to help them grow faster.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-center">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-stopwatch h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>On Time Delivery</h5>
                                    <p className="text-muted mb-0">
                                        We determine the ending based on our research and planning stages, which gives us momentum over time.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-center">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-user-md h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>Customer oriented</h5>
                                    <p className="text-muted mb-0">
                                        Our goal is to ensure a positive customer experience during and after the software implementation.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-center">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-desktop-cloud-alt h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>Modern Technologies</h5>
                                    <p className="text-muted mb-0">
                                        Based on the solutions required to accomplish the intended goal, we choose the finest technologies to apply.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </section>

            <section className=" bg-light d-table w-100">
                <div className="container">
                    <br /><br /><br />
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="title-heading ">
                                <h5 className="title mb-2  wow animate__animated animate__fadeInUp" data-wow-delay=".1s"> Get the best product on the market now</h5>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <span className="textColor fw-bold">Doltech</span> is an agile software house that produces applications of the highest quality.
                                    We have completed a number of projects for companies of all sizes,  from individual
                                    startups to large corporations. We build trusting relationships with our customers and
                                    openly share our working methods and the principles on which we are based.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a href="about-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Read More</button></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/home/agile.webp" width="350" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".1s"
                                alt="" />
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>

            <section className=" d-table w-100">
                <br /><br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Our Services</h4>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We are motivated to address complicated issues and provide transformational <br /> digital solutions by our long-term, integrated strategy.
                                </p>
                                <div className="">
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row align-items-center mt-4">
                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/home/web.webp" width="300" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".1s"
                                alt="" />
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="title-heading mt-4">
                                <h5 className="title mb-2  wow animate__animated animate__fadeInUp" data-wow-delay=".1s"> Web Development</h5>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    We do this with your business goals in mind, not just website development.
                                    We provide you with the knowledge and technology you need to influence
                                    your product. From the best UX and product design to effective development.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a href="web-developement" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Read More</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>

            <section className="bg-light d-table w-100">
                <br /><br /><br />
                <div className="container">
                    <div className="row align-items-center mt-4">
                        <div className="col-md-6 col-12">
                            <div className="title-heading">
                                <h5 className="title mb-2  wow animate__animated animate__fadeInUp" data-wow-delay=".1s"> Mobile Development</h5>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Provide a smooth and efficient experience across all your devices. We help
                                    you to develop intuitive, native, and cross-platform mobile apps for iOS
                                    and Android.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a href="mobile-app-development" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Read More</button></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/home/mobile.webp" width="300" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".1s"
                                alt="" />
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>

            <section className="d-table w-100">
                <br /><br /><br />
                <div className="container">
                    <div className="row align-items-center mt-4">
                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="../../../../assets/images/home/ml.webp" width="300" className="img-fluid d-block mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".1s"
                                alt="" />
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="title-heading mt-4">
                                <h5 className="title mb-2  wow animate__animated animate__fadeInUp" data-wow-delay=".1s"> Machine Learning</h5>
                                <p className="text-muted mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Harness the power of machine learning and generative AI. You can implement
                                    dedicated ML solutions or integrate tools like ChatGPT to optimize business
                                    costs, improve internal efficiencies, and gain a competitive advantage
                                </p>
                                <div className="mt-4 pt-2">
                                    <a href="machine-learning" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Read More</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>

            <section className="bg-light d-table w-100">
                <br /><br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title mb-4 pb-2 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <h4 className="title mb-4">Your Partners in Product Delivery</h4>
                                <p className="text-muted mb-0 mx-auto">
                                    You will collaborate with a group of specialists who are skilled in every stage of the
                                    product delivery process <br /> right from the start of the product journey. They want to assist
                                    you in precisely identifying market demands, choosing the <br /> best technology for your product,
                                    and developing a competitive edge.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 mt-4 pt-2 text-center">
                            <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded bg-light wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link rounded active" id="community-tab" data-bs-toggle="pill" href="#pills-community" role="tab" aria-controls="pills-community" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Discovery</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="selfservice-tab" data-bs-toggle="pill" href="#pills-self" role="tab" aria-controls="pills-self" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Validation</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="teamwork-tab" data-bs-toggle="pill" href="#pills-teamwork" role="tab" aria-controls="pills-teamwork" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Delivery</h6>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link rounded" id="maintenance-tab" data-bs-toggle="pill" href="#pills-maintenance" role="tab" aria-controls="pills-maintenance" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Maintenance</h6>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link rounded" id="hand-Off-tab" data-bs-toggle="pill" href="#pills-hand-Off" role="tab" aria-controls="pills-hand-Off" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Hand-Off</h6>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4 pt-2">
                            <div className="tab-content" id="pills-tabContent">

                                <div className="tab-pane fade show active" id="pills-community" role="tabpanel" aria-labelledby="community-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/discovery.webp" className="img-fluid mx-auto d-block shadow rounded" alt="Product Discovery" />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ms-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Product Discovery</h5>
                                                <p className="text-muted">
                                                    The exciting beginning of our relationship for the delivery of our products
                                                    occurs at this point, which goes much beyond the conventional, basic project
                                                    management methodology. The needs of the end users and the issue that the
                                                    solution will be tackling are our main concerns. We also want to begin outlining
                                                    the problem we can solve, the available market-based solutions, and the value
                                                    we can offer potential customers.
                                                </p>
                                                <div class="accordion" id="buyingquestion">
                                                    <div class="accordion-item rounded">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                Discovery Workshop
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                                            data-bs-parent="#buyingquestion">
                                                            <div class="accordion-body text-muted">
                                                                We will be able to achieve alignment on the product's mission and
                                                                vision thanks to this session. We will collaborate with you to identify
                                                                the particular issue or pain point that needs to be addressed. How
                                                                much pain is this? And what real benefits would tackling it actually
                                                                bring? Who are your users exactly? We will have a far better chance of
                                                                creating a successful product if we can provide answers to these issues.                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-self" role="tabpanel" aria-labelledby="selfservice-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title me-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Early Market Validation</h5>
                                                <p className="text-muted">
                                                    We now have a thorough grasp of the issue and a theory for a potential fix. Assessing
                                                    the accuracy of our assumptions is the next step. This phase's objective is to rapidly
                                                    and inexpensively determine from our prospective users whether it is worthwhile to
                                                    develop our solution.
                                                </p>
                                                <div class="accordion mt-4" id="buyingquestion">
                                                    <div class="accordion-item rounded">
                                                        <h5 class="accordion-header" id="headingOne">
                                                            <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                Mockups and experiments
                                                            </button>
                                                        </h5>
                                                        <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                                            data-bs-parent="#buyingquestion">
                                                            <div class="accordion-body text-muted">
                                                                We will conduct a series of simple experiments to verify the most important
                                                                theories on the appeal, viability, and profitability of your product.
                                                                Different early-stage testing techniques, such wireframe mockups, can aid in
                                                                validating products already on the market and minimizing waste. This is a
                                                                fantastic opportunity to obtain the first insightful user input on the concept.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="accordion-item rounded mt-2">
                                                        <h2 class="accordion-header" id="headingTwo">
                                                            <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                aria-expanded="false" aria-controls="collapseTwo">
                                                                Design Sprint
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                                            data-bs-parent="#buyingquestion">
                                                            <div class="accordion-body text-muted">
                                                                We can quickly transform an idea into a working prototype through the use of Design
                                                                Sprint workshops. It only takes four days to complete the sprint, and you only need
                                                                to participate in the first two days.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="accordion-item rounded mt-2">
                                                        <h2 class="accordion-header" id="headingThree">
                                                            <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                UX research and testing
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                                                            data-bs-parent="#buyingquestion">
                                                            <div class="accordion-body text-muted">
                                                                Full-fledged product development is costly and fraught with danger, as it runs the
                                                                risk of failing to satisfy clients. We will collaborate with you to develop a more
                                                                complete prototype and test it with actual users through our networks or database
                                                                of focus groups using the preliminary information acquired at the mockup stage.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="accordion-item rounded mt-2">
                                                        <h2 class="accordion-header" id="headingFour">
                                                            <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                Landing pages
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                                                            data-bs-parent="#buyingquestion">
                                                            <div class="accordion-body text-muted">
                                                                As part of user testing, we can also create straightforward landing pages that can
                                                                serve as your product's minimum viable product (MVP), complete with a powerful
                                                                analytics engine that will allow you to track traffic and button clicks and obtain
                                                                insightful information about user behavior.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 order-1 order-md-2">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/validation.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-teamwork" role="tabpanel" aria-labelledby="teamwork-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/delivery.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ms-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Product Delivery</h5>
                                                <p className="text-muted">
                                                    We can proceed to the stage of product development if it has been determined that a product idea
                                                    has a good probability of being successful. We take pleasure in having a smooth product delivery
                                                    procedure that involves frequent communication with you. The correct Scrum implementation is
                                                    where we start, then we concentrate on data-driven development. What else should you anticipate
                                                    at this point in the procedure?
                                                </p>
                                                <div className="mt-4">
                                                    <div class="accordion mt-4" id="buyingquestion">
                                                        <div class="accordion-item rounded">
                                                            <h5 class="accordion-header" id="headingOne">
                                                                <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                    aria-expanded="true" aria-controls="collapseOne">
                                                                    Agile delivery
                                                                </button>
                                                            </h5>
                                                            <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                                                data-bs-parent="#buyingquestion">
                                                                <div class="accordion-body text-muted">
                                                                    We can proceed to the stage of product development if it has been determined that a
                                                                    product idea has a good probability of being successful. We take pleasure in having
                                                                    a smooth product delivery procedure that involves frequent communication with you.
                                                                    The correct Scrum implementation is where we start, then we concentrate on data-driven
                                                                    development. What else should you anticipate at this point in the procedure?
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="accordion-item rounded mt-2">
                                                            <h2 class="accordion-header" id="headingTwo">
                                                                <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                                    Agile in scale
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                                                data-bs-parent="#buyingquestion">
                                                                <div class="accordion-body text-muted">
                                                                    In order to scale your product infrastructure and stabilize your proof of concept (PoC) or
                                                                    minimum viable product (MVP), you must handle some significant technical debt. You'll be
                                                                    able to tweak your product's finer points to make it as good as it can be given the
                                                                    circumstances of the market.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-maintenance" role="tabpanel" aria-labelledby="maintenance-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ms-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Product Maintenance</h5>
                                                <p className="text-muted">
                                                    Once your product is operational, it will probably need some continuing upkeep. This phase's objectives
                                                    include fixing any remaining defects, addressing potential security concerns, and making any necessary
                                                    minor improvements. A thorough infrastructure health check might also be included. Why is the phase of
                                                    product maintenance crucial?
                                                </p>
                                                <div className="mt-4">
                                                    <ul class="list-unstyled text-muted">
                                                        <li class="mb-1"><span class="text-primary iconColor h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>It will keep your product in a good condition to satisfy changing market needs.</li>
                                                        <li class="mb-1"><span class="text-primary iconColor h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>It covers all technological upgrades, resulting in a continued high-quality user experience.</li>
                                                        <li class="mb-1"><span class="text-primary iconColor h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Any small bugs can be quickly and efficiently managed.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/maintenance.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-hand-Off" role="tabpanel" aria-labelledby="hand-Off-tab">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                                <img src="../../../../assets/images/home/hand-off.webp" className="img-fluid mx-auto d-block shadow rounded" alt="" />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ms-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                                <h5 className="mb-4">Product Hand-Off</h5>
                                                <p className="text-muted">
                                                    Once your product is operational, it will probably need some continuing upkeep. This phase's objectives
                                                    include fixing any remaining defects, addressing potential security concerns, and making any necessary
                                                    minor improvements. A thorough infrastructure health check might also be included. Why is the phase of
                                                    product maintenance crucial?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </section>

            <section className=" d-table w-100 letMakeItHappen">
                <br /><br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success could be yours</h4>
                                <h5 className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    Let’s make it happen.
                                </h5>
                                <div className="">
                                    <a href="" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".13">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br /><br />
            </section>
        </div>
    );
}

export default Home;