import React from "react";
import { Link } from "react-router-dom";
import '../index.css';

const About = () => {
    return (
        // Main Body of Section Starts
        <div className="App">
            {/* About us section starts */}
            <section className="bg-half-170 d-table w-100" id="aboutUsImage">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading title-heading">
                                <h2 className="text-white title-dark"> About us </h2>
                                <p className="text-white mb-0 mx-auto">
                                    Imagine your company expanding to new heights with Doltech Ltd.'s assistance. <br />
                                    We support your future goal-setting and achievement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About us section ends */}

            {/* Curvy Section Starts */}
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* Curvy Section Ends */}

            {/* Who We Are Section Starts */}
            <section className="section">
                <br /> <br />
                <div className="container">
                    <div className="row align-items-center" id="counter">
                        <div className="col-md-6">
                            <img src="../../../../assets/images/web_dev/web_dev_process.webp" width="" className="img-fluid  wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="ms-lg-4">
                                <div className="d-flex mb-4  wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                    <span className="textColor h1 mb-0"><span className="counter-value display-1 fw-bold" data-target="15">5</span>+</span>
                                    <span className="h6 align-self-end ms-2">Years <br /> Experience</span>
                                </div>
                                <div className="section-title">
                                    <h4 className="title mb-4 cash  wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Who we are ?</h4>
                                    <p className="text-muted  wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                        At <span className="textColor fw-bold">Doltech Ltd, </span>
                                        we painstakingly create custom software solutions for potential
                                        startups and established businesses. Our team has a wealth of experience creating
                                        custom mobile and online applications that scale well after collaborating with
                                        funded startups, rapidly expanding businesses, and established enterprises.
                                    </p>
                                    <Link to="/contact-us" className="btn getinTouch mt-3  wow animate__animated animate__fadeInUp" data-wow-delay=".4s">Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                <div className="container mt-4 border-top">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/dart.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/python.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/cpp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/java.svg" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/csharp.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="../../../../assets/images/clients/ruby.png" height="40" className="avatar wow animate__animated animate__fadeInUp" data-wow-delay=".1s" alt="" />
                        </div>
                    </div>
                </div>
                <br />
            </section>
            {/* Who We Are Section Ends */}

            {/* How We Are Section Starts */}
            <section className="section bg-light">
                <br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2 mt-4">
                                <h6 className="textColor fw-bold wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Work Process</h6>
                                <h4 className="title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">First Steps on Your Doltech Product Journey</h4>
                                <p className="text-muted para-desc mx-auto mb-0 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    From the very beginning of your product journey, you’ll work with an expert team,
                                    skilled in all areas of the product delivery process.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-left wow animate__animated animate__fadeInUp" data-wow-delay=".1">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-stopwatch h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>Meeting with our business advisors</h5>
                                    <p className="text-muted mb-0">
                                        You will get the chance to talk about your business idea, share your hopes and
                                        concerns, and learn more about the agile product delivery method used by Doltech.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-left wow animate__animated animate__fadeInUp" data-wow-delay=".2">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-user-md h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>Meet with our experts</h5>
                                    <p className="text-muted mb-0">
                                        You will be given the opportunity to discuss the specifics of your product and your
                                        marketing strategy during a creative session with our business and technical professionals.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-12 mt-5">
                            <div className="features feature-primary text-left wow animate__animated animate__fadeInUp" data-wow-delay=".3">
                                <div className="image position-relative d-inline-block">
                                    <i className="uil uil-user-md h2 iconColor"></i>
                                </div>

                                <div className="content mt-4">
                                    <h5>Kick-off with the stage most suited to your needs</h5>
                                    <p className="text-muted mb-0">
                                        Based on the solutions required to accomplish the intended goal, we choose the finest technologies to apply.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>
            {/* How We Work Section Ends */}

            {/* Let Make It Happened Section Starts */}
            <section className=" d-table w-100 letMakeItHappen">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title text-white wow animate__animated animate__fadeInUp" data-wow-delay=".1s">The next market success</h4>
                                <p className="text-white mx-auto wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    could be yours. Let’s make it happen.
                                </p>
                                <div className="">
                                    <a href="contact-us" className=" me-2"><button className="btn btn-light readMore wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Contact us now</button></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <br /><br />
            </section>
            {/* Let Make It Happened Section Ends */}

            {/* Vision, Values and Mission Section Starts */}
            <section className="section">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".1">Doltech Vision, Mission & Core Values</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 pt-2 justify-content-center">
                        <div className="col-lg-8 col-md-12 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".2">
                            <ul className="nav nav-pills bg-light rounded nav-justified flex-column flex-sm-row shadow" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link rounded active" id="pills-dayone-tab" data-bs-toggle="pill" href="#pills-dayone" role="tab" aria-controls="pills-dayone" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Vision Statement</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="pills-daytwo-tab" data-bs-toggle="pill" href="#pills-daytwo" role="tab" aria-controls="pills-daytwo" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Mission Statement</h6>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link rounded" id="pills-daythree-tab" data-bs-toggle="pill" href="#pills-daythree" role="tab" aria-controls="pills-daythree" aria-selected="false">
                                        <div className="text-center py-2">
                                            <h6 className="mb-0">Core Values</h6>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-dayone" role="tabpanel" aria-labelledby="pills-dayone-tab">
                                    <div className="row">
                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="card event-schedule event-primary rounded border wow animate__animated animate__fadeInUp" data-wow-delay=".1">
                                                <div className="card-body">
                                                    <div className="d-flex wow animate__animated animate__fadeInUp" data-wow-delay=".1">
                                                        <ul className="date text-center me-3 mb-0 list-unstyled">
                                                            <li className="day fw-bold mb-2">1</li>
                                                        </ul>
                                                        <div className="flex-1 content">
                                                            <h4><Link to="#!" className="text-dark title">Vision Statement</Link></h4>
                                                            <hr />
                                                            <p className="text-muted location-time">
                                                                To be a global standard (brand) in software engineering solutions and services
                                                            </p>
                                                            <Link to="/contact-us" className="btn btn-sm readMore">Contact us</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-daytwo" role="tabpanel" aria-labelledby="pills-daytwo-tab">
                                    <div className="row">
                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="card event-schedule event-primary rounded border wow animate__animated animate__fadeInUp" data-wow-delay=".2">
                                                <div className="card-body">
                                                    <div className="d-flex wow animate__animated animate__fadeInUp" data-wow-delay=".2">
                                                        <ul className="date text-center me-3 mb-0 list-unstyled">
                                                            <li className="day fw-bold mb-2">2</li>
                                                        </ul>
                                                        <div className="flex-1 content">
                                                            <h4><Link to="#!" className="text-dark title">Mission Statement</Link></h4>
                                                            <hr />
                                                            <p className="text-muted location-time">
                                                                To provide high-quality and efficient software solutions to serve human needs.
                                                            </p>
                                                            <Link to="/contact-us" className="btn btn-sm readMore">Contact us</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-daythree" role="tabpanel" aria-labelledby="pills-daythree-tab">
                                    <div className="row">
                                        <div className="col-md-12 mt-4 pt-2">
                                            <div className="card event-schedule event-primary rounded border wow animate__animated animate__fadeInUp" data-wow-delay=".3">
                                                <div className="card-body">
                                                    <div className="d-flex wow animate__animated animate__fadeInUp" data-wow-delay=".3">
                                                        <ul className="date text-center me-3 mb-0 list-unstyled">
                                                            <li className="day fw-bold mb-2">3</li>
                                                        </ul>
                                                        <div className="flex-1 content">
                                                            <h4><Link to="#!" className="text-dark title">Core Values</Link></h4>
                                                            <hr />
                                                            <p className="text-muted location-time">
                                                                To provide high-quality and efficient software solutions to serve human needs.
                                                            </p>
                                                            <ul className="list-unstyled text-muted">
                                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><span className="fw-bold ">Excellence:</span>  Our products and services reflect our brand. We invest heavily in our processes, technologies, and people in order to achieve excellence.</li>
                                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><span className="fw-bold">Innovation:</span> Through research and development, we leverage technology to be innovative in our products and services</li>
                                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><span className="fw-bold">Customer Satisfaction:</span> Our customers are our prized assets and we value them as such, hence ensuring their needs are always first on our priority list. </li>
                                                            </ul>
                                                            <Link to="/contact-us" className="btn btn-sm readMore">Contact us</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>
            {/* Vision, Values and Mission Section End */}

        </div>
        // Main Body of Section Ends
    );
}

export default About;