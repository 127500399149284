import React from "react";
import { Link } from "react-router-dom";


function WebHeader() {

    const toggleMenu = () => {
        document.getElementById('isToggle').classList.toggle('open');
        var isOpen = document.getElementById('navigation')
        if (isOpen.style.display === "block") {
            isOpen.style.display = "none";
        } else {
            isOpen.style.display = "block";
        }
    };

    return (
        <header id="topnav" className="defaultscroll sticky shadow-lg">
            <div className="container">
                {/* Logo container */}
                <Link className="logo" to="/">
                    <img src="./assets/images/logo/logo.png" height="40" className="logo-light-mode" alt="" />
                </Link>
                {/* Logo End */}

                <div class="menu-extras">
                    <div class="menu-item">
                        {/* <!-- Mobile menu toggle--> */}
                        <Link class="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                        {/* <!-- End mobile menu toggle--> */}
                    </div>
                </div>

                {/* Email Section Start */}
                <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item">
                        <img src="../assets/images/welcome/socials/mail.svg" className="me-1" alt="icon" /><span className="mailDoltech">info@doltech.io</span>
                    </li>
                </ul>
                {/* Email Section Ends */}

                <div id="navigation">
                    {/* <!-- Navigation Menu-->    */}
                    <ul class="navigation-menu">
                        <li><a href="/" class="sub-menu-item active">Home</a></li>

                        <li class="has-submenu parent-parent-menu-item">
                            <a href="about-us">About us</a>
                        </li>

                        <li class="has-submenu parent-parent-menu-item" style={{display: 'none'}}>
                            <Link to="#!">Services</Link><span class="menu-arrow"></span>
                             <ul class="submenu">
                                <li><Link class="sub-menu-item"></Link></li>
                                <li><Link class="sub-menu-item"></Link></li>
                                <li><Link class="sub-menu-item">DevOps</Link></li>
                                <li><Link class="sub-menu-item">MLOps</Link></li>
                                <li><Link class="sub-menu-item">Blockchain</Link></li>
                                <li><Link class="sub-menu-item">Testing</Link></li>
                                <li><Link class="sub-menu-item">FinTech</Link></li>
                            </ul>
                            <ul class=" submenu">
                                <li><Link to="web-developement" class="sub-menu-item">Web Development</Link></li>
                                <li><Link class="sub-menu-item">Mobile Development</Link></li>
                                <li><Link class="sub-menu-item">UI & UX Design</Link></li>
                                <li><Link class="sub-menu-item">Machine Learning</Link></li>
                                <li><Link class="sub-menu-item">ChatGPT Integration</Link></li>
                            </ul>
                        </li>

                        <li class="has-submenu parent-parent-menu-item">
                            <a href="javascript:void(0)">Services</a><span class="menu-arrow"></span>
                            <ul class="submenu megamenu">
                                <li>
                                    <ul>
                                        <li><a href="web-developement" class="sub-menu-item"><i class="uil uil-desktop fs-6 align-middle me-1"></i> Web Development</a></li>
                                        {/* <li><a href="ui-badges.html" class="sub-menu-item"><i class="uil uil-brackets-curly fs-6 align-middle me-1"></i> DevOps</a></li> */}
                                    </ul>
                                </li>

                                <li>
                                    <ul>
                                    <li><a href="mobile-app-development" class="sub-menu-item"><i class="uil uil-mobile-android fs-6 align-middle me-1"></i> Mobile Development</a></li>
                                    {/* <li><a href="ui-text.html" class="sub-menu-item"><i class="uil uil-arrow fs-6 align-middle me-1"></i> MLOps</a></li> */}
                                    </ul>
                                </li>

                                <li>
                                    <ul>
                                        <li><a href="ui-ux-design" class="sub-menu-item"><i class="uil uil-object-ungroup fs-6 align-middle me-1"></i> UI & UX Design</a></li>
                                        {/* <li><a href="ui-border.html" class="sub-menu-item"><i class="uil uil-box fs-6 align-middle me-1"></i> Blockchain</a></li> */}
                                    </ul>
                                </li>
                        
                                <li>
                                    <ul>
                                        <li><a href="machine-learning" class="sub-menu-item"><i class="uil uil-robot fs-6 align-middle me-1"></i> Machine Learning</a></li>
                                        {/* <li><a href="ui-avatar.html" class="sub-menu-item"><i class="uil uil-file-check-alt fs-6 align-middle me-1"></i> Testing</a></li> */}
                                    </ul>
                                </li>
                        
                                <li>
                                    <ul>
                                        <li><a href="chatgpt-integration" class="sub-menu-item"><i class="uil uil-table fs-6 align-middle me-1"></i> ChatGPT Integration</a></li>
                                        {/* <li><a href="ui-icons.html" class="sub-menu-item"><i class="uil uil-gold fs-6 align-middle me-1"></i> FinTech</a></li> */}
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li class="has-submenu parent-parent-menu-item">
                            <a href="porfolio">Portfolio</a>
                        </li>

                        <li class="has-submenu parent-menu-item">
                            <a href="contact-us">Contact us</a>
                        </li>
                    </ul>
                    {/* <!--end navigation menu--> */}
                </div>
                {/* <!--end navigation--> */}
            </div>
        </header>
    );
}

export default WebHeader;